import axios from 'axios'
    
    const baseUrl = 'https://tsmiscwebapi.azurewebsites.net/api/';
    const getUrl = url => `${baseUrl}${url}`;
    const header = {
        headers: {'Content-Type': 'application/problem+json; charset=utf-8' }
    }

    //General GET
    export async function get(url) { 

        return await axios.get(
            getUrl(url),
        );
    }

    //General POST 
    export async function post(url, data) {
        // adding header to body
        const body = Object.assign(data , header)
        try{
            return await axios.post(
                getUrl(url),
                body
            );
        }
        catch (error){
            console.log(error);
            throw error;
        }
    }
  
    //POST REQ Login
    export async function postUpd(url, params){
        return new Promise(function (resolve, reject){
            axios.post(getUrl(url), params)

            .then(function (response){
                // console.log(response.data);
                resolve(response.data)
            })
            .catch((error) => {
                console.log('error from the rest client', error)
            })
        })
    }
    
    //GET REQ Login
    export async function getLog(url){
        return new Promise(function (resolve, reject){
            axios.get(getUrl(url))

            .then(function (response){
                // console.log(response.data);
                resolve(response.data)
            })
            .catch((error) => {
                console.log('error from the rest client', error)
                alert("Invalid Login information")
                
            })
        })
    }
